import { useEffect, useRef, useState } from "react";
import "./styles/style.scss";
import { useDispatch, useSelector } from "react-redux";
import indexSliceState, {
    setTotalMarkers,
    setStates,
    setPropertyTypes,
    setIsLoggedIn,
    setIsPaidUser,
    setIsUnpaidUser,
    setSubscriptionExpired,
    setSubscriptionDaysLeft,
    setIsLenderUser,
    setPropertyCardItem,
    setMapViewPortCenterCoords,
    setPropertyCardClassName,
    setIsLimitedUser,
    setIsLeasingAdmin
} from "./store";
import favoritePropertySliceState from "./store/favoriteProperty";
import { hotjar } from "react-hotjar";
// import Loader from "./assets/images/loader.gif";
import { useAuth0 } from "@auth0/auth0-react";

// Component imports
import BlockHeader from "./components/blocks/BlockHeader";
import BlockMap from "./components/blocks/BlockMap";
import BlockMemberPopup from "./components/blocks/BlockMemberPopup";
import BlockGetFinancingPopup from "./components/blocks/BlockGetFinancingPopup";
import BlockLenderFlyout from "./components/blocks/sidebar/findLender/BlockLenderFlyout";
import BlockSidebar from "./components/blocks/sidebar/BlockSidebar";

// Domain imports
import StateService from "./domain/state/StateService";
import StateViewMapper from "./domain/state/StateViewMapper";
import PropertyService from "./domain/property/PropertyService";
import PropertyViewMapper from "./domain/property/PropertyViewMapper";
import TotalMarkerCountEntity from "./domain/property/totalMarkerCount/TotalMarkerCountEntity";
import PropertyTypeService from "./domain/propertyType/PropertyTypeService";
import PropertyTypeViewMapper from "./domain/propertyType/PropertyTypeViewMapper";
import isPaidUserHook from "./hooks/isPaidUserHook";
import BlockLegend from "./components/blocks/sidebar/BlockLegend";
import BlockFiltersPopup from "./components/blocks/BlockFiltersPopup";
import BlockDashboard from "./components/blocks/BlockDashboard";
import responsiveSliceState, { setIsMobile } from "./store/responsive";
import BlockMobilePropertySearch from "./components/blocks/BlockMobilePropertySearch";
import PartMapButton from "./components/parts/PartMapButton";
import BlockFavoritesPopup from "./components/blocks/BlockFavoritesPopup";
import {
    DIRECTORY_API,
    FIND_LENDER_API,
    LEASING_API,
    PROPERTY_DATA_API,
    USER_DATA_API
} from "./apiList";
import { useSearchParams } from "react-router-dom";
import BlockCalculatorSlideout from "./components/blocks/BlockCalculatorSlideout";
import BlockAffiliatedPopup from "./components/blocks/BlockAffiliatedPopup";
import BlockShareCalculatorPopup from "./components/blocks/BlockShareCalculatorPopup";
import BlockOverwriteCalculations from "./components/blocks/BlockOverwriteCalculations";
import BlockInvestmentTrackerSlidout from "./components/blocks/sidebar/BlockInvestmentTrackerSlidout";
// import BlockLeasingAdminPortalSlideout from "./components/blocks/BlockLeasingAdminPortalSlideout";

function App() {
    const dispatch = useDispatch();
    const { getAccessTokenSilently, user } = useAuth0();
    const sliceState = useSelector(indexSliceState);
    const responsiveState = useSelector(responsiveSliceState);
    const favoritePropertiesState = useSelector(favoritePropertySliceState);
    const windowSize = useRef([window.innerWidth]);
    const [searchParams, setSearchParams] = useSearchParams();

    // TO-DO: Move to redux state
    const [searchIsTriggered, setSearchIsTriggered] = useState(false);
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

    //Check if user is logged in and store it in redux state
    useEffect(() => {
        user && dispatch(setIsLoggedIn(true));
    }, [user]);

    // Check whether the user is free or paid member and store it in redux state
    // Example of usage in Property card component
    isPaidUserHook().then(
        ({
            isPaidUser,
            isLeasingAdmin,
            subscriptionExpired,
            daysLeft,
            isUnpaidUser,
            isLenderUser,
            isLimitedUser
        }) => {
            dispatch(setIsPaidUser(isPaidUser));
            dispatch(setIsLeasingAdmin(isLeasingAdmin));
            dispatch(setIsUnpaidUser(isUnpaidUser));
            dispatch(setIsLenderUser(isLenderUser));
            dispatch(setSubscriptionExpired(subscriptionExpired));
            dispatch(setSubscriptionDaysLeft(daysLeft));
            dispatch(setIsLimitedUser(isLimitedUser));
        }
    );
    useEffect(() => {
        // When getAccessTokenSilently from auth0 is ready, set its reference to correspondent property in API Client
        PROPERTY_DATA_API.setAccessTokenSilentlyReference(
            getAccessTokenSilently
        );
        DIRECTORY_API.setAccessTokenSilentlyReference(getAccessTokenSilently);
        FIND_LENDER_API.setAccessTokenSilentlyReference(getAccessTokenSilently);
        USER_DATA_API.setAccessTokenSilentlyReference(getAccessTokenSilently);
        LEASING_API.setAccessTokenSilentlyReference(getAccessTokenSilently);
    }, [getAccessTokenSilently]);

    useEffect(() => {
        StateService.get().then((response) => {
            dispatch(setStates(StateViewMapper.map(response.items)));
        });
        PropertyTypeService.get().then((response) => {
            dispatch(
                setPropertyTypes(PropertyTypeViewMapper.map(response.items))
            );
        });
    }, []);

    // useEffect(() => {
    //     sliceState.isLoggedIn &&
    //         VendorRoleService.getVendorRoles().then((response) => {
    //             dispatch(
    //                 setVendorRoles(VendorRoleViewMapper.map(response.items))
    //             );
    //         });
    // }, [sliceState.isLoggedIn]);

    useEffect(() => {
        // TODO if authenticated call this endpoint, otherwise ignore it
        user &&
            PropertyService.getMarkerTotalCount().then(
                (response: TotalMarkerCountEntity) => {
                    const mapped = PropertyViewMapper.mapTotalMarkers(response);
                    dispatch(setTotalMarkers(mapped));
                }
            );
    }, [user]);

    useEffect(() => {
        if (
            process.env.REACT_APP_HOTJAR_ID &&
            process.env.REACT_APP_HOTJAR_HJSV
        ) {
            hotjar.initialize(
                +process.env.REACT_APP_HOTJAR_ID,
                +process.env.REACT_APP_HOTJAR_HJSV
            );
        }
        if (hotjar.initialized()) {
            hotjar.event("Session Start");
        }
    }, []);

    useEffect(() => {
        dispatch(setIsMobile(windowSize.current[0] < 768 ? true : false));
    }, []);

    useEffect(() => {
        if (
            responsiveState.isMobile &&
            (responsiveState.overlays.hamburgerMenu ||
                responsiveState.overlays.propertySearch ||
                responsiveState.overlays.lenderDefault ||
                responsiveState.currentView === "dashboard")
        ) {
            dispatch(
                setPropertyCardItem({
                    data: null,
                    isVisible: false
                })
            );

            dispatch(setMapViewPortCenterCoords(undefined));
            dispatch(setPropertyCardClassName(""));
            searchParams.delete("propertyId");
            setSearchParams(searchParams);
        }
    }, [responsiveState]);

    return (
        <div className="app-container">
            {/* {sliceState.trialPopupOpen && !sliceState.isPaidUser ? (
                <BlockTrialPopup
                    subscriptionDaysLeft={sliceState.subscriptionDaysLeft}
                    subscriptionExpired={sliceState.subscriptionExpired}
                />
            ) : null} */}
            <BlockFiltersPopup
                searchIsTriggered={searchIsTriggered}
                setSearchIsTriggered={setSearchIsTriggered}
            />
            <div
                className={`main-container ${
                    isMenuCollapsed ? "sidebar-collapsed" : ""
                }`}
            >
                {responsiveState.currentView === "dashboard" && (
                    <BlockDashboard />
                )}
                <BlockMobilePropertySearch
                    searchIsTriggered={searchIsTriggered}
                    setSearchIsTriggered={setSearchIsTriggered}
                />

                {user && (
                    <BlockLenderFlyout isMenuCollapsed={isMenuCollapsed} />
                )}
                <BlockSidebar
                    isMenuCollapsed={isMenuCollapsed}
                    setIsMenuCollapsed={setIsMenuCollapsed}
                />
                {sliceState.showLpInvestmentPopup.isOpen && (
                    <BlockInvestmentTrackerSlidout />
                )}
                <BlockHeader
                    searchIsTriggered={searchIsTriggered}
                    setSearchIsTriggered={setSearchIsTriggered}
                    isMenuCollapsed={isMenuCollapsed}
                    setIsMenuCollapsed={setIsMenuCollapsed}
                />
                <BlockLegend />
                <BlockMap isSidebarCollapsed={isMenuCollapsed} />
                {/* Avoid unecessary re-renders by conditionally rendering component */}
                {sliceState.calculatorSlideout && <BlockCalculatorSlideout />}
                {/* {sliceState.leasingAdminSlideout && (
                    <BlockLeasingAdminPortalSlideout />
                )} */}
            </div>
            {sliceState.iframe.show && <BlockMemberPopup />}
            {sliceState.iframe.getFinancingType && <BlockGetFinancingPopup />}
            {responsiveState.showMapBtn && <PartMapButton />}
            {favoritePropertiesState.favoritesPopup &&
                sliceState.propertyCardItem.data?._id?._value && (
                    <BlockFavoritesPopup
                        propertyId={
                            sliceState.propertyCardItem.data?._id?._value ??
                            sliceState.propertyCardItem.data?.id
                        }
                    />
                )}
            {sliceState.affilliatedPopup && <BlockAffiliatedPopup />}
            {sliceState.shareCalculatorPopup.isOpen && (
                <BlockShareCalculatorPopup />
            )}
            {sliceState.overwriteCalculatorPopup.calculatorShareId && (
                <BlockOverwriteCalculations
                    shareId={
                        sliceState.overwriteCalculatorPopup.calculatorShareId
                    }
                />
            )}
        </div>
    );
}
export default App;
// export default withAuthenticationRequired(App, {
//     // Show a message while the user waits to be redirected to the login page.
//     onRedirecting: () => (
//         <div className="property-card__loading-map">
//             <img
//                 style={{
//                     width: "128px",
//                     transition: "350ms ease"
//                 }}
//                 src={Loader}
//                 alt="Loader"
//             />
//             {/* Loading... */}
//         </div>
//     )
// });
